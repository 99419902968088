<template>
  <v-dialog v-model="isOpen" width="500">
    <v-card>
      <v-toolbar color="text-h6" dark>{{ title }}</v-toolbar>

      <v-card-text class="mt-4 text-h6">
        {{ body }}
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey darken-1" text @click="$emit('cancel')">
          {{ cancelBtnText }}
        </v-btn>
        <v-btn color="green" text @click="$emit('confirm')">
          {{ acceptBtnText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmDialog',

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    body: {
      type: String,
      default: '',
    },
    acceptBtnText: {
      type: String,
      default: 'Accept',
    },
    cancelBtnText: {
      type: String,
      default: 'Cancel',
    },
  },
};
</script>
